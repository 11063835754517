 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Berikut ini adalah pernyataan resmi Inforsys terkait Covid-19</h1>

      <p class="text-muted font-size-15 mb-4">
        Untuk keluarga besar Inforsys dimanapun Anda berada, Ini adalah surat dari CEO PT Inforsys Indonesia untuk Anda staff Inforsys, Partner, Pelanggan, Supplier dan semua yang terlibat dalam ekosistem Inforsys terkait penyebaran COVID-19 (Virus Corona) yang telah memengaruhi kita semua.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Salam sejahtera untuk kita semua
      </p>
      <p class="text-muted font-size-15 mb-4">
        Inforsys memiliki komitmen untuk bersama-sama terlibat secara aktif menahan laju penularan virus Corona, untuk itu berikut adalah kebijakan Inforsys untuk melakukan tindakan preventif penyebaran virus Corona demi kesehatan dan keselamatan kita bersama:
      </p>
      <ul class="text-muted font-size-15 mb-4">
        <li>Mohon setiap orang menjaga kebersihan diri. Kantor Inforsys menyediakan Hand Sanitizer untuk pencegahan dan meminimalkan penularan virus. Wajib menggunakan hand sanitizer saat akan memasuki area kantor dan saat meninggalkan area kantor.</li>
        <li>Mohon menghindari tempat ramai seperti Mall dan pusat-pusat keramaian lainnya. Jika memungkinkan gunakan selalu masker dan tidak berinteraksi secara fisik dengan orang lain.</li>
        <li>Semua meeting dan training harap dilakukan secara online. Kami menggunakan aplikasi <a href="https://zoom.us">Zoom.us</a> , <a href="https://www.microsoft.com/en/microsoft-365/microsoft-teams/group-chat-software">Microsoft Teams</a> atau <a href="https://anydesk.com/en">Anydesk</a> untuk melakukan meeting dan training secara online.</li>
        <li>Hindari bersentuhan secara langsung, seperti salaman.
          <a (click)="openWindowCustomClass(videocontent)"><span class="title mb-3">
          <u>Klik Video ini</u></span></a> sebagai alternatif cara bersalaman tanpa bersentuhan dengan tanpa mengurangi rasa hormat.</li>
        <li>Larangan untuk datang ke kantor Inforsys bagi Anda yang mengalami demam atau flu, batuk, pilek dan sesak nafas. Segera periksakan diri ke dokter atau hubungi 112 atau lapor ke Dinas Kesehatasn setempat.</li>
        <li>Larangan untuk datang ke kantor Inforsys bagi Anda yang memiliki anggota keluarga serumah yang sedang sakit, seperti flu, demam, batuk, sesak nafas, Segera periksakan diri ke dokter atau hubungi 112 atau lapor ke Dinas Kesehatan setempat.</li>
        <li>Jika Anda di diagnosa terjangkit Virus Corona atau pernah berinteraksi langsung dengan mereka yang terjangkit virus corona, dan pernah datang ke kantor Inforsys atau berinteraksi dengan keluarga besar Inforsys, baik itu staff, partner, pelanggan,  maka segera info ke HRD Inforsys secepatnya untuk penanganan lebih lanjut. Email : rita@inforsys.co.id atau whatsapp ke : +62 812 7042 4400</li>
        <li>Mulai senin tanggal 4 Mai 2020 Kantor Batam akan melakukan sterilizasi karyawan dan Tamu yang masuk melalui thermal Scanner terlebih dahulu oleh resepsionis Jika ada yang tidak lolos Thermal Sanner yakni diatas suhu tubuh melebihi 37 derajat maka akan diminta pulang, meninggalkan area kantor, dan segera melakukan pemeriksaan ke dokter.</li>
      </ul>
      <p class="text-muted font-size-15 mb-4">
        Untuk Pelanggan dan Partner Inforsys. Kami mengerti bahwa sebagai pelanggan dan partner bergantung pada produk Inforsys sepanjang waktu. Untuk itu, kami saat ini berusaha keras untuk memastikan produk dan layanan Inforsys tetap dapat diakses demi memenuhi kebutuhan semua pihak.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Salam hormat<br>
        <br>
        CEO PT Inforsys Indonesia<br>
        <br>
        Orchard Walk Blok C#18<br>
        Jl. Orchard Boulevard<br>
        Orchad Park Batam<br>
        Batam Kota, Kepulauan Riau 29464<br>
        Indonesia<br>
        Phone : +62 778 416 0250<br>
        Mobile: +62 811 7757 634<br>
        Email : hello@inforsys.co.id<br>
        Website : inforsys.co.id<br>
      </p>
    </div>
  </section>
  <ng-template #videocontent let-modal class="video-modal">
    <div class="modal-header">
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body p-0">
      <youtube-player [videoId]="'99BMvyKjZqo'" height="450" width="780">
      </youtube-player>
    </div>
  </ng-template>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
