 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">SOFI for Distributor Company</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              Lebih dari hal yang bersifat standart dalam sebuah aplikasi akuntansi, SOFI Simple ERP menyiapkan banyak fitur unggulan bagi perusahaan dagang.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_logistics_x4dc.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        Anda dapat menggunakan fitur Kapitalisasi Harga Pokok dalam pembebanan biaya yang ditimbulkan dari pengadaan barang dagang. Seperti halnya biaya angkut, biaya bongkar muat pelabuhan, biaya upah supir, dan sebagainya. Aplikasi ini juga menyediakan fitur Kapasitas Kendaraan dan Customer Distance yang berfungsi untuk mengetahui dengan rinci kapasitas kendaraan yang dimiliki serta mengetahui jarak tempuh ke pelanggan untuk pengiriman barang dari gudang. Dengan demikian, bisa diketahui biaya bahan bakar yang digunakan untuk mengirim barang. SOFI juga menyiapkan berbagai metode perhitungan harga pokok, seperti FIFO, LIFO, ataupun AVERAGE.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Dari sisi pembayaran, SOFI Simple ERP menyiapkan fitur cheque payment serta monitoring-nya. Untuk kebutuhan Pembayaran (Payment), akan ada status cheque transit dan cheque clear. Untuk kebutuhan Penerimaan (Receipt) disiapkan cheque on hand, cheque transit, dan juga cheque clear. Cheque yang berstatus in transit belum menyebabkan status pembayaran hutang ataupun piutang lunas. Untuk memudahkan Anda mengontrol status cheque tersebut, SOFI Simple ERP menyiapkan modul cheque monitoring.
      </p>
    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
