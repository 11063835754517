import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndexComponent } from './index/index.component';

import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxYoutubePlayerModule } from 'ngx-youtube-player';

import { PagesRoutingModule } from './pages-routing.module';

import { SharedModule } from '../shared/shared.module';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { ServiceCompaniesComponent } from './service-companies/service-companies.component';
import { ManufactureCompaniesComponent } from './manufacture-companies/manufacture-companies.component';
import { DistributionCompaniesComponent } from './distribution-companies/distribution-companies.component';
import { ConstructionCompaniesComponent } from './construction-companies/construction-companies.component';
import { Covid19Component } from './covid19/covid19.component';

@NgModule({
  declarations: [IndexComponent, PrivacyComponent, TermsConditionsComponent, SoftwareImplementationComponent, TrainingComponent, AnnualMaintenanceComponent, CustomizeModuleComponent, AboutComponent, ServiceCompaniesComponent, ManufactureCompaniesComponent, DistributionCompaniesComponent, ConstructionCompaniesComponent, Covid19Component],
  imports: [
    CommonModule,
    PagesRoutingModule,
    SharedModule,
    ScrollToModule.forRoot(),
    NgbModalModule,
    NgxYoutubePlayerModule
  ]
})
export class PagesModule { }
