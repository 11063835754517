import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IndexComponent } from './index/index.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { SoftwareImplementationComponent } from './software-implementation/software-implementation.component';
import { TrainingComponent } from './training/training.component';
import { AnnualMaintenanceComponent } from './annual-maintenance/annual-maintenance.component';
import { CustomizeModuleComponent } from './customize-module/customize-module.component';
import { AboutComponent } from './about/about.component';
import { ServiceCompaniesComponent } from './service-companies/service-companies.component';
import { ManufactureCompaniesComponent } from './manufacture-companies/manufacture-companies.component';
import { DistributionCompaniesComponent } from './distribution-companies/distribution-companies.component';
import { ConstructionCompaniesComponent } from './construction-companies/construction-companies.component';
import { Covid19Component } from './covid19/covid19.component';

const routes: Routes = [
  {
      path: '',
      component: IndexComponent
  },
  {
    path: 'index',
    component: IndexComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'privacy',
    component: PrivacyComponent
  },
  {
    path: 'terms-conditions',
    component: TermsConditionsComponent
  },
  {
    path: 'software-implementation',
    component: SoftwareImplementationComponent
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'annual-maintenance',
    component: AnnualMaintenanceComponent
  },
  {
    path: 'customize-module',
    component: CustomizeModuleComponent
  },
  {
    path: 'distribution-companies',
    component: DistributionCompaniesComponent
  },
  {
    path: 'manufacture-companies',
    component: ManufactureCompaniesComponent
  },
  {
    path: 'construction-companies',
    component: ConstructionCompaniesComponent
  },
  {
    path: 'service-companies',
    component: ServiceCompaniesComponent
  },
  {
      path: 'pernyataan-resmi-inforsys-terkait-covid-19',
      component: Covid19Component
  }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PagesRoutingModule { }
