 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">SOFI for Manufacture Company</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              SOFI for Manufacture Company
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_factory_dy0a.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <br>
      <h3>Mendefinisikan “Master Data Production” Secara Terperinci</h3>
      <p class="text-muted font-size-15 mb-4">
        Memudahkan Anda dalam mendefinisikan produk jadi yang terdiri dari komponen raw material, consumable, alokasi biaya tenaga kerja langsung, dan alokasi biaya overhead pabrik.  Anda juga dapat mendaftarkan urutan-urutan produksi ke dalam Routing dan Mesin Produksi yang digunakan. Jika terjadi perubahan formula produksi, Anda dapat membuat versi formula produksi yang baru
      </p>

      <h3>Menghitung Work in Process (WIP) dari Proses Produksi</h3>
      <p class="text-muted font-size-15 mb-4">
        Produk jadi yang belum diselesaikan  proses produksi-nya di akhir periode akan dicatat sebagai Barang Dalam Proses atau Work in Process (WIP). WIP mengandung komponen biaya bahan baku serta biaya konversi sampai dengan urutan routing produksi terakhir yang sudah dilaluinya. SOFICloud memudahkan Anda untuk melacak dan mengetahui nilai detail dari seluruh WIP yang terjadi.
      </p>

      <h3>Analisa kebutuhan stock melalui Material Requirement Planning</h3>
      <p class="text-muted font-size-15 mb-4">
        Ketika perusahaan mendapat Order Penjualan dari pelanggan, maka system akan menyajikan daftar ketersediaan raw material, consumable, serta bahan pendukung lainnya untuk proses produksi. Anda dapat menganalisa stock on hand, booking stock, serta availability stock.  Jika raw material sedang dalam proses pembelian, maka system akan menyajikan data order stock untuk memudahkan bagian planner dalam merencanakan produksi
      </p>

      <h3>Procurement System yang Handal</h3>
      <p class="text-muted font-size-15 mb-4">
        Proses Procurement dimulai dari Purchase Request dari department yang membutuhkan produk, dilanjutkan dengan memilih supplier dari penawaran harga yang mereka berikan. Sistem dapat menyajikan laporan perbandingan harga untuk memudahkan Anda mengambil keputusan.  Anda dapat mendefinisikan produk per supplier sebagai pemasok utama, mencatat lead time produk untuk memudahkan perencanaan produksi. Proses penerbitan PO dan penerimaan barang melalui GRN dapat dilakukan partial maupun full. Sistem menyediakan system monitoring terperinci untuk mengetahui status PR, PO yang sudah full ataupun yang masih Outstanding detil per produk
      </p>

      <h3>Monitoring Proses Produksi </h3>
      <p class="text-muted font-size-15 mb-4">
        Data order dari pelanggan (Sales Order) dilanjutkan ke bagian produksi melalui Manfucturing Order untuk segera di produksi sesuai permintaan. Jika tahapan produksi (routing) yang dilalui panjang serta membutuhkan waktu produksi yang panjang juga, maka sangat dibutuhkan tools monitoring produksi yang dapat memudahkan kita dalam mengontrol dan mengetahui status produk sudah sampai di tahapan apa.  SOFICloud membantu melakukan traceable produk terperinci sehingga  sangat membatu manajemen memantau proses produksi yang terjadi.  Manufacturing Order akan menyajikan tahapan-tahapan proses produksi yang telah dilakukan dan sudah menghasilkan berapa banyak produk jadi ataupun barang dalam proses (WIP)
      </p>

      <h3>Penentuan Harga Pokok Produk Dengan Metode Standard Costing</h3>
      <p class="text-muted font-size-15 mb-4">
        Setiap urutan produksi (production routing) memberikan kontribusi terhadap harga pokok produk.  Komponen utamanya terdiri atas Raw Material, Direct Labour (DL), dan FOH. Untuk komponen biaya DL dan FOH, ditetapkan menggunakan standart costing.  Di akhir periode, system akan membandingkan Actual Cost Production yang terjadi dengan Cost Apply (Standart Costing) yang dibebankan ke produk. Informasi selisih Actual Cost dan Standart Cost Apply akan ditampilkan sebagai Cost Adjustment untuk menggambarkan seberapa besar Efisiensi Produksi yang dilakukan
      </p>
    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
