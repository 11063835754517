<!-- Footer Start -->
<section class="footer" style="background-image: url(assets/images/footer-bg.png)">
  <div class="container">
    <div class="row">
      <div class="col-lg-4">
        <div class="mb-5">
          <img src="assets/images/inforsys-white.png" alt="" class="" height="24">
          <p class="text-white-50 my-4 font-size-15">
            PT Inforsys Indonesia<br>
            Orchard Walk Blok C#18<br>
            Jl. Orchard Boulevard<br>
            Orchad Park Batam<br>
            Batam Kota<br>
            Kepulauan Riau 29464</p>
          <ul class="list-inline footer-social-icon-content">
            <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="facebook"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="twitter"></i-feather>
              </a></li>
            <li class="list-inline-item mr-4"><a href="https://www.instagram.com/inforsys.co.id/" class="footer-social-icon">
                <i-feather name="instagram"></i-feather>
              </a></li>
            <li class="list-inline-item"><a href="javascript: void(0);" class="footer-social-icon">
                <i-feather name="linkedin"></i-feather>
              </a></li>
          </ul>
        </div>
      </div>
      <div class="col-lg-7 offset-lg-1">
        <div class="row">
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-4">About Us</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="about" class="footer-link">About Us</a></li>
              <li><a href="terms-conditions" class="footer-link">Terms & Conditions</a></li>
              <li><a href="privacy" class="footer-link">Privacy Policy</a></li>
              <li><a href="pernyataan-resmi-inforsys-terkait-covid-19" class="footer-link">Covid-19</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Customers</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="manufacture-companies" class="footer-link">Manufacture</a></li>
              <li><a href="distribution-companies" class="footer-link">Distributor</a></li>
              <li><a href="construction-companies" class="footer-link">Construction</a></li>
              <li><a href="service-companies" class="footer-link">Services</a></li>
            </ul>
          </div>
          <div class="col-md-4">
            <h6 class="text-white text-uppercase mb-3">Support</h6>
            <ul class="list-unstyled footer-sub-menu">
              <li><a href="software-implementation" class="footer-link">Software Implementation</a></li>
              <li><a href="training" class="footer-link">Training & Re-Implementation</a></li>
              <li><a href="annual-maintenance" class="footer-link">Annual Software Maintenance</a></li>
              <li><a href="customize-module" class="footer-link">Customize Module</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="footer-alt py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="text-center">
          <p class="text-white-50 font-size-15 mb-0">{{year}} © SOFIClOUD, a part of PT Inforsys Indonesia</p>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Footer End -->
