 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->


<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">About SOFI</h1>
      <p class="text-muted font-size-15 mb-4">
        SOFI adalah sistem komputer akuntansi lengkap ter-integrasi. Di rancang untuk menangani tingginya volume transaksi dengan dukungan database handal Microsoft SQL Server.
      </p>

      <p class="text-muted font-size-15 mb-4">
        Program SOFI telah teruji dengan stabil pada lebih dari 250 pelanggan korporasi kami yang tersebar di Jakarta, Surabaya, Batam, dan berbagai wilayah lainnya di Indonesia. SOFI telah digunakan oleh perusahaan dengan berbagai tipe bisnis seperti perkebunan, manufaktur, perkapalan, perdagangan, dan lainnya.
      </p>

      <p class="text-muted font-size-15 mb-4">
        Anda dapat mendefinisikan kertas kerja di SOFI dengan memasukkan kontrol status transaksi. Fungsi ini akan menggantikan kertas kerja manual seperti persetujuan dokumen, revisi, ataupun tanda tangan. SOFI juga menyediakan fitur analisa biaya per Departemen, Divisi, ataupun per Project. Dengan demikian kontrol biaya akan dapat tersajikan secara lebih detil dan lebih terperinci.
      </p>

    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
