 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Training & Re-Implementation</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              Pemahaman individu terhadap produk secara utuh dan detil di organisasi memegang peranan cukup penting di awal siklus implementasi software maupun untuk menjamin keberlangsungan penggunaan software.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_exams_g4ow.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        Disamping hal tersebut, pertukaran individu di dalam organisasi dan proses transfer knowledge yang tidak berjalan lancar menjadi kendala utama keberlangsungan penggunaan software.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Kami menyediakan layanan training ulang produk untuk fitur-fitur khusus yang dibutuhkan dan juga re-implementasi produk jika ternyata proses implementasi awal di perusahaan Anda menghadapi kegagalan.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Perusahaan Anda juga akan memerlukan re-implementasi ulang untuk software yang sama ketika dilakukan ekspansi bisnis dengan pembukaan cabang perusahaan baru ataupun pembentukan anak perusahaan. Kami akan melakukan duplikasi software untuk kemudian dilakukan implementasi ulang dengan SOP perusahaan yang sudah berjalan baik.
      </p>
      <p class="text-muted font-size-15 mb-4">
        Dengan strategi implementasi yang baik dan penuh kedisiplinan, akan sangat menentukan kesuksesan re-implementasi dan mengurangi resiko kegagalan.
      </p>
    </div>
  </section>

  <!-- Hero End -->
  <app-footer></app-footer>
</div>
