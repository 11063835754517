 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Annual Software Maintenance</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              Mengapa sebuah aplikasi software perlu maintenance (pemeliharaan) berkala?
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_maintenance_cn7j.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        Berikut ini adalah beberapa alasan yang menunjukkan urgensi maintenance software:
      </p>
      <ul class="text-muted font-size-15 mb-4">
        <li>Software kemungkinan memiliki kesalahan (bugs) yang dulunya belum terdeteksi. Dengan kondisi demikian, bugs tersebut perlu selalu dipantau dan dilakukan perbaikan.</li>
        <li>Software harus bisa di-modifikasi karena permintaan baru ataupun kebutuhan penting dari perusahaan.</li>
        <li>Software bisa saja mengalami kerusakan yang disebabkan oleh beberapa faktor eksternal, contohnya hacking, virus atau human error lainnya.</li>
        <li>Software kemungkinan mengalami perubahan karena faktor regulasi eksternal (misalnya aturan pemerintah). Dengan demikian perlu dilakukan update ataupun penyesuaian tertentu.</li>
        <li>Software perlu senantiasa dikembangkan dan ditingkatkan (improvement) secara teknologi. Harus bisa meng-adopsi kemajuan teknologi.</li>
        <li>Harus disiapkan manajemen backup data rutin untuk mengantisipasi kerusakan yang mungkin saja terjadi.</li>
        <li>Dengan service “Annual Software Maintenance” yang kami sediakan, kami akan melakukan perlindungan komprehensif untuk keamanan data dan maksimalnya investasi IT yang telah Anda lakukan.</li>
      </ul>
      <p class="text-muted font-size-15 mb-4">
        Sebagai strategi maintenance software tahunan tersebut, kami melakukan perawatan secara berkala untuk membantu organisasi Anda menghadapi permasalahan yang mungkin akan terjadi. Berikut adalah software maintenance yang kami lakukan.
      </p>
      <ul class="text-muted font-size-15 mb-4">
        <li>Onsite support untuk permasalahan prioritas tinggi</li>
        <li>Remote support jika terjadi bugs atau kendala software lainnya</li>
        <li>Layanan konsultasi produk</li>
        <li>Chatting group, email, blog, telepon</li>
        <li>Update dan rilis terbaru untuk perbaikan software berkala</li>
        <li>Auto backup database di lokasi penyimpanan yang berbeda untuk menjaga keamanan data</li>
        <li>Kunjungan rutin ke pelanggan untuk perbaikan kualitas produk serta layanan</li>
        <li>Customer Gathering Program untuk komunitas pengguna produk</li>
        <li>Survey kepuasan pelanggan</li>
        <li>Tip dan trik aplikasi</li>
      </ul>
    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
