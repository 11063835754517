 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->


<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">SOFI for Shipyard/Construction Company</h1>
      <div class="row align-items-center justify-content-center">
        <div class="col-lg-6">
          <div class="w-75 mb-5 mb-lg-0">
            <p class="text-muted mb-5 pb-5 font-size-17">
              SOFI Cloud telah dipercaya oleh banyak perusahaan perkapalan ataupun konstruksi untuk menghitung secara akurat Laporan Project Costing mereka.
            </p>
          </div>
        </div>
        <div class="col-lg-6 col-md-10">
          <div class=" mt-5 mt-lg-0">
            <img src="assets/images/undraw_Golden_gate_bridge_jkph.svg" alt="" class="img-fluid d-block mx-auto">
          </div>
        </div>
      </div>
      <p class="text-muted font-size-15 mb-4">
        SOFI mampu mendefinisikan project costing ke analisa yang lebih detil seperti Departement Costing dan juga Division Costing. Setiap direct material, direct labour, subcont cost maupun biaya overhead lainnya yang digunakan untuk kebutuhan project, department dan juga divisi, dapat disajikan secara detil dalam periode tertentu guna memudahkan analisa manajemen terhadap costing suatu project.
      </p>

      <p class="text-muted font-size-15 mb-4">
       SOFI memiliki fitur material budgeting untuk mem-proteksi pengeluaran material project (stock issue by project) jika telah melebihi batas budgeting yang ditentukan. Begitu juga dengan project costing lainnya, dapat dilengkapi informasi budgeting di awal pengerjaannya sehingga dengan mudah dapat dilihat performance project yang sedang berjalan. Sudah melewati batas atau masih dalam budget yang ditentukan.
      </p>

      <p class="text-muted font-size-15 mb-4">
        Keseluruhan modul yang ada di aplikasi SOFI terintegrasi satu sama lainnya. Sistem pembelian secara otomatis akan terhubung ke sistem persediaan (inventory). Dengan dukungan fitur Multi Warehouse, dan juga berbagai metode perhitungan harga pokok persediaan seperti FIFO, LIFO, dan juga AVERAGE sangat membantu menganalisa persediaan di perusahaan perkapalan. Sistem mampu menampilkan minimum stock, ataupun allocated stock untuk project tertentu.
      </p>

      <p class="text-muted font-size-15 mb-4">
        Keseluruhan modul yang ada di aplikasi SOFI terintegrasi satu sama lainnya. Sistem pembelian secara otomatis akan terhubung ke sistem persediaan (inventory). Dengan dukungan fitur Multi Warehouse, dan juga berbagai metode perhitungan harga pokok persediaan seperti FIFO, LIFO, dan juga AVERAGE sangat membantu menganalisa persediaan di perusahaan perkapalan. Sistem mampu menampilkan minimum stock, ataupun allocated stock untuk project tertentu.
      </p>
    </div>
  </section>

  <!-- Hero End -->
  <app-footer></app-footer>
</div>
