<!-- Pricing Start -->
<section class="section bg-light" id="pricing">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-6">
        <div class="text-center mb-5">
          <h3 class="title mb-3">Choose Your Plan</h3>
          <p class="text-muted font-size-15">Powerful software shouldn't be expensive</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="pricing-box rounded text-center p-4">
          <div class="pricing-icon-bg my-4">
            <i class="mdi mdi-cloud-check h1"></i>
          </div>
          <h4 class="title mb-3">On Cloud</h4>
          <h1 class="font-weight-bold mb-0"><b><sup class="h4 mr-2 font-weight-bold">Rp</sup>500K</b></h1>
          <p class="text-muted font-weight-semibold">User / Month</p>
          <ul class="list-unstyled pricing-item mb-4">
            <li class="text-muted">5 first user only</li>
            <li class="text-muted">Users: Unlimited</li>
            <li class="text-muted">Implementation : Start Rp.60JT</li>
            <li class="text-muted">Support: Yes</li>
          </ul>
          <a href="https://api.whatsapp.com/send?phone=628117757634&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F" class="btn btn-outline-primary pr-btn">Contact Us</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="pricing-box rounded text-center active p-4">
          <div class="pricing-icon-bg my-4">
            <i class="mdi mdi-server h1"></i>
          </div>
          <h4 class="title mb-3">On Promise</h4>
          <h1 class="font-weight-bold mb-0"><b><sup class="h4 mr-2 font-weight-bold">Rp</sup>500K</b></h1>
          <p class="text-muted font-weight-semibold">User / Month</p>
          <ul class="list-unstyled pricing-item mb-4">
            <li class="text-muted">10 first user only</li>
            <li class="text-muted">Users: Unlimited</li>
            <li class="text-muted">Implementation : Start Rp.125JT</li>
            <li class="text-muted">Support: Yes</li>
          </ul>
          <a href="https://api.whatsapp.com/send?phone=628117757634&text=Hello%20Inforsys%2C%20saya%20tertarik%20dengan%20produk%20Inforsys%20%2C%20bisakah%20dijelaskan%20lebih%20lanjut%3F" class="btn btn-primary pr-btn">Contact Us</a>
          <div class="mt-4">
            <div class="hero-bottom-img">
              <img src="assets/images/pricing-bottom-bg.png" alt="" class="img-fluid d-block mx-auto">
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</section>
<!-- Pricing End -->
