 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']">
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Terms and Conditions</h1>
      <h3 class="title mb-3">Ketentuan Layanan</h3>
      <p class="">
        Dengan mendaftar untuk layanan PT Inforsys Indonesia ("Layanan") atau pun salah satu layanan dari PT Inforsys Indonesia [inforsys.co.id, sofilive.com, pisilive.com, dokuku.com, sofilite.com, soficloud.com] (disebut sebagai "Inforsys") Anda telah setuju untuk terikat dengan syarat dan ketentuan berikut ("Persyaratan Layanan"). Setiap fitur baru atau alat yang ditambahkan ke Layanan ini juga akan tunduk pada Persyaratan Layanan. Anda dapat melihat versi terbaru dari Ketentuan Layanan setiap saat di sini. Inforsys berhak untuk memperbarui dan mengubah Ketentuan Layanan dengan posting update dan perubahan ke situs web Inforsys.
      </p>
      <p class="">
        Anda disarankan untuk memeriksa Ketentuan Layanan dari waktu ke waktu untuk setiap update atau perubahan yang dapat mempengaruhi Anda. Anda harus membaca, menyetujui dan menerima semua syarat dan ketentuan yang tercantum dalam Syarat persetujuan Layanan dan Kebijakan Privasi Inforsys sebelum Anda dapat menggunakan layanan Inforsys.
      </p>

      <h3 class="title mb-3">Ketentuan Umum</h3>
      <p class="">
        Anda harus membaca, menyetujui dan menerima semua syarat dan ketentuan dalam Perjanjian Pengguna ini dan Kebijakan Privasi sebelum Anda menggunakan produk dan layanan Inforsys.
      </p>
      <ol>
        <li>Anda tidak boleh menggunakan layanan Inforsys untuk tujuan ilegal atau tidak sah atau dalam hal lainnya, dalam penggunaan Layanan, melanggar hukum negara Anda (termasuk namun tidak terbatas pada undang-undang hak cipta).</li>
        <li>Anda setuju untuk tidak mereproduksi, menduplikasi, menyalin, menjual, menjual kembali, atau mengeksploitasi bagian manapun dari Layanan, penggunaan Layanan, atau akses ke Layanan tanpa izin tertulis oleh Inforsys.</li>
        <li>Anda memahami bahwa Konten Anda (tidak termasuk informasi kartu kredit), dapat ditransfer tanpa terenkripsi dan melibatkan (a) ditransmisikan lewat berbagai jaringan; dan (b) perubahan agar memenuhi dan sesuai dengan persyaratan teknis jaringan atau peralatan. Informasi Kartu Kredit selalu dienkripsi selama transfer melalui jaringan.</li>
        <li>Anda mengakui dan setuju bahwa penggunaan Layanan, termasuk informasi yang dikirimkan ke atau disimpan oleh Inforsys, diatur oleh kebijakan privasi sebagaimana tercantum pada hal tersebut. Anda telah membaca & memahami Kebijakan Privasi terlebih dahulu ketika memutuskan untuk menggunakan layanan Inforsys lebih lanjut.</li>
      </ol>

      <h3 class="title mb-3">Inforsys Berhak</h3>
      <ol>
        <li>Memodifikasi atau menghentikan Layanan untuk alasan apapun, tanpa pemberitahuan setiap saat.</li>
        <li>Menolak layanan kepada siapa saja untuk alasan apapun setiap saat.</li>
        <li>Menghapus Konten dan Akun yang mengandung konten yang kami tentukan berdasarkan pertimbangan kami sendiri telah melanggar hukum, menyinggung, mengancam, memfitnah, menipu, berbau pornografi, cabul atau tidak pantas atau melanggar kekayaan intelektual pihak atau Persyaratan Layanan.</li>
        <li>Menutup akun dengan segera jika terjadi Pelecehan verbal atau tertulis apapun (termasuk ancaman kekerasan atau retribusi) dari setiap terhadap pelanggan Inforsys, karyawan Inforsys, anggota, atau petugas.</li>
        <li>Memberikan layanan kami kepada pesaing Anda dan tidak membuat janji eksklusivitas dalam setiap segmen pasar tertentu. Anda juga tahu dan sepakat bahwa karyawan Inforsys dan kontraktor juga mungkin pelanggan / pedagang dan bahwa mereka mungkin bersaing dengan Anda, meskipun mereka mungkin tidak menggunakan informasi rahasia Anda dalam melakukannya.</li>
        <li>Meminta dokumentasi untuk menentukan atau mengkonfirmasi kepemilikan akun jika terjadi perselisihan mengenai kepemilikan akun. Dokumentasi dapat mencakup, tetapi tidak terbatas pada, salinan izin usaha Anda, salinan identitas diri, empat digit terakhir dari kartu kredit yang digunakan, dll</li>
        <li>Menentukan, dalam keputusan tunggal kami, kepemilikan rekening yang sah dan mentransfer akun untuk pemilik yang sah. Jika kami tidak dapat menentukan pemilik akun yang sah, Inforsys berhak untuk menonaktifkan sementara akun yang dimaksud hingga resolusi telah ditentukan antara pihak-pihak yang berselisih</li>
      </ol>

      <h3 class="title mb-3">Batasan Tanggung Jawab</h3>
      <ol>
        <li>Anda mengerti dan menyetujui bahwa Inforsys tidak bertanggung jawab untuk kerugian langsung, tidak langsung, insidental, khusus, konsekuensial, termasuk namun tidak terbatas pada, kerusakan/ kehilangan keuntungan, peluang, penggunaan, data atau kerugian tak berwujud lain yang dihasilkan dari penggunaan atau ketidakmampuan untuk menggunakan layanan ini.</li>
        <li>Dalam hal apapun Inforsys atau reseller kami tidak bertanggung jawab atas kehilangan keuntungan atau kerusakan khusus, insidental atau konsekuensial kerugian yang timbul dari atau sehubungan dengan situs kami, layanan kami atau perjanjian ini (namun timbul termasuk kelalaian). Anda setuju untuk mengganti kerugian dan menjaga kami dan (sebagaimana berlaku) perusahaan kami, anak perusahaan, afiliasi, mitra Inforsys, petugas, direktur, agen, dan karyawan, tidak dirugikan dari setiap klaim atau permintaan, termasuk biaya pengacara yang wajar, yang dilakukan oleh pihak ketiga karena dengan atau yang timbul dari pelanggaran Anda terhadap Perjanjian ini atau dokumen yang menggabungkan dengan referensi, atau pelanggaran Anda terhadap hukum atau hak-hak pihak ketiga.</li>
        <li>Penggunaan Layanan adalah risiko Anda sendiri. Layanan ini disediakan atas dasar "sebagaimana adanya" dan "sebagaimana tersedia" tanpa jaminan atau kondisi, mengungkapkan, tersirat atau perundang-undangan.</li>
        <li>Inforsys tidak menjamin bahwa Layanan tidak akan terganggu, tepat waktu, aman, atau bebas dari kesalahan.</li>
        <li>Inforsys tidak menjamin bahwa hasil yang mungkin diperoleh dari penggunaan Layanan akan akurat atau dapat diandalkan.</li>
        <li>Inforsys tidak menjamin bahwa kualitas produk, layanan, informasi, atau materi lain yang dibeli atau diperoleh oleh Anda melalui Layanan akan memenuhi harapan Anda, atau bahwa setiap kesalahan dalam Layanan akan diperbaiki.</li>
      </ol>

      <h3 class="title mb-3">Kekayaan Intelektual dan Konten Pelanggan</h3>
      <ol>
        <li>Kami tidak mengklaim hak kekayaan intelektual atas materi yang Anda berikan kepada layanan Inforsys. Semua materi yang Anda upload tetap menjadi milik dan tanggung jawab Anda. Anda dapat menghapus data Anda setiap saat dengan menghapus akun Anda. Ini juga akan menghapus semua konten yang telah disimpan dalam Layanan.</li>
        <li>Kami tidak akan mengungkapkan informasi rahasia Anda kepada pihak ketiga, kecuali jika diperlukan dalam rangka memberikan layanan kami. Informasi rahasia termasuk bahan atau informasi diberikan oleh Anda kepada kami yang sifatnya tidak diketahui publik. Informasi rahasia tidak termasuk informasi yang: (a) berada di domain publik pada saat kami menerima itu; (b) masuk ke dalam domain publik setelah kami menerima itu menjadi bukan kesalahan kami; (c) informasi yang kami terima dari orang lain selain Anda tanpa pelanggaran kerahasiaan mereka; atau (d) informasi Anda yang kami diwajibkan oleh hukum untuk mengungkapkan.</li>
      </ol>

      <h3 class="title mb-3">Pembayaran Biaya Layanan(*)</h3>
      <ol>
        <li>Layanan ini akan ditagih 10 hari sebelum masa kadaluarsa paket yang Anda pilih. Ketika periode penagihan berakhir pemilik Rekening akan dikirimkan sebuah faktur melalui email yang diberikan. Selain itu, faktur akan muncul pada halaman dashboard Anda. Pengguna memiliki sekitar dua minggu untuk memunculkan dan menyelesaikan masalah dengan penagihan.</li>
        <li>Inforsys berhak untuk memperhitungkan dan memasukkan semua biaya eksklusif dari semua pemerintah, provinsi, negara atau pemerintah lain yang berkaitan dengan penjualan barang dan jasa, atau pajak, biaya atau biaya yang sekarang berlaku atau diberlakukan di masa mendatang.</li>
        <li>Inforsys tidak melayani pengembalian uang dari paket layanan yang telah Anda bayar.</li>
      </ol>

      <h3 class="title mb-3">Reseller(*)</h3>
      <ol>
        <li>Inforsys menyediakan layanan konsultasi dan merupakan pihak independen yang dapat membantu Anda, jika Anda menemukan kesulitan, untuk membangun, mengoperasikan dan mengoptimalkan toko Inforsys Anda. Inforsys tidak melakukan pelayanan Konsultan pihak ketiga dan tidak berafiliasi dengan Konsultan manapun.</li>
        <li>Inforsys tidak bertanggung jawab atas pekerjaan yang dilakukan oleh salah satu Konsultan pihak ketiga atau kegagalan untuk memenuhi perintah kerja. Link ke situs-situs Konsultan, pengumuman tentang layanan mereka atau penawaran, dan tanggapan terhadap email pertanyaan mengenai layanan Consultant, disediakan hanya untuk tujuan informasi atas kebijaksanaan Inforsys dan tidak akan dianggap sebagai bagian dari, atau afiliasi dari layanan Inforsys.</li>
        <li>Inforsys tidak dalam kewajiban menanggung kerugian dari yang bersifat langsung, tidak langsung, insidental, khusus, konsekuensial atau lain apapun, termasuk, tanpa batasan, langsung, tidak langsung, insidental, khusus, konsekuensial, atau kerusakan yang diakibatkan dari hubungan kontrak antara Anda dan konsultan pihak ketiga.</li>
      </ol>

      <h3 class="title mb-3">Pembatalan dan Pemutusan Layanan(*)</h3>
      <ol>
        <li>Anda dapat membatalkan akun Anda kapan saja dengan mengirim email ke hello@inforsys.co.id dan kemudian mengikuti petunjuk tertentu yang ditunjukkan kepada Anda dalam menanggapi pemberhentian penggunaan layanan Inforsys ini.</li>
        <li>Setelah pembatalan dikonfirmasi, semua toko Konten Anda akan segera dihapus dari Layanan. Karena penghapusan semua data bersifat final, semua data yang telah dihapus tidak dapat dikembalikan lagi.</li>
        <li>Setelah penghapusan data, Inforsys tidak bertanggung jawab terhadap data anda</li>
        <li>Jika Anda membatalkan Layanan, Inforsys tidak bertanggung jawab atau mengembalikan segala biaya yang telah Anda bayarkan semasa penggunaan layanan.</li>
        <li>Pelanggaran terhadap Syarat Pelayanan akan mengarah kepada Pembatalan & Pemutusan Layanan sepihak oleh Inforsys. Dan dalam kondisi tertentu kami berhak untuk memodifikasi atau menghentikan layanan Inforsys untuk alasan apapun, tanpa pemberitahuan setiap saat.</li>
      </ol>

      <h3 class="title mb-3">Modifikasi ke Layanan dan Harga(*)</h3>
      <ol>
        <li>Harga atau biaya untuk menggunakan Inforsys dapat berubah sewaktu-waktu. Pemberitahuan tersebut dapat diberikan setiap saat dengan memposting perubahan pada situs Inforsys [inforsys.co.id, sofilive.com, pisilive.com, dokuku.com] atau Dashboard Anda melalui pengumuman. Perubahan harga akan berlaku pada transaksi layanan berikutnya dan Inforsys tidak akan mengenakan kenaikan biaya tersebut atas layanan yang telah Anda bayarkan sebelumnya.</li>
        <li>Inforsys berhak setiap waktu ke waktu untuk mengubah atau menghentikan, Layanan (atau bagiannya) dengan atau tanpa pemberitahuan.</li>
        <li>Inforsys tidak bertanggung jawab kepada Anda atau kepada pihak ketiga manapun atas modifikasi, perubahan harga, suspensi atau penghentian pemberian Layanan ini.</li>
      </ol>

      <h3 class="title mb-3">Peralatan Pendukung(*)</h3>
      <ol>
        <li>Inforsys dapat memberi Anda akses ke alat-alat pihak ketiga dimana Inforsys tidak memiliki kontrol atau monitor langsung dari alat-alat tersebut.</li>
        <li>Anda mengakui dan menyetujui bahwa Inforsys menyediakan akses ke alat-alat seperti 'apa adanya' tanpa jaminan, representasi atau kondisi apapun dan tanpa dukungan apapun. Inforsys tidak akan memiliki kewajiban apapun yang timbul dari atau terkait dengan penggunaan opsional alat pihak ketiga.</li>
        <li>Setiap penggunaan oleh Anda alat opsional yang ditawarkan melalui situs ini sepenuhnya risiko Anda sendiri dan Anda harus memastikan bahwa Anda sudah jelas dengan dan menyetujui persyaratan pengadaan alat-alat yang disediakan oleh penyedia pihak ketiga terkait.</li>
        <li>Inforsys sangat menganjurkan bahwa pedagang mendapatkan masukan ahli sebelum menggunakan atau mengandalkan alat-alat tertentu.</li>
      </ol>

      <h3 class="title mb-3">Laporan Pelanggaran Layanan(*)</h3>
      <ol>
        <li>Inforsys mendukung perlindungan kekayaan intelektual dan meminta customer Inforsys untuk melakukan hal yang sama.</li>
        <li>Jika seseorang mendapatkan dan percaya bahwa salah satu customer kami melanggar hak kekayaan intelektual mereka, mereka dapat mengirim Laporan Pelanggaran Layanan kepada kami di hello@inforsys.co.id untuk ditindaklanjuti lebih lanjut.</li>
        <li>Setelah menerima Pemberitahuan tersebut, kami dapat menginvestigasi, menghapus atau menonaktifkan akses ke materi dari pelaku pelanggaran hak cipta sesuai dengan bukti yang ditemukan.</li>
      </ol>

      <h3 class="title mb-3">Pertanyaan Lebih Lanjut</h3>
      <p class="">
        Apabila Anda memiliki pertanyaan atau masukan atas Syarat Layanan yang tertuang di atas, silakan menuliskan email kepada kami dengan mengirim ke hello@inforsys.co.id
      </p>
    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
