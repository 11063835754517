 <!--Navbar Start-->
 <nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark" (window:scroll)="windowScroll()"
 id="navbar">
 <div class="container">
   <!-- LOGO -->
   <a class="navbar-brand logo" href="/">
     <img src="assets/images/soficloud.png" alt="" class="logo-dark" height="28" />
   </a>
   <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
     aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
     <i class="mdi mdi-menu"></i>
   </button>
   <div class="collapse navbar-collapse" id="navbarCollapse">
     <ul class="navbar-nav mx-auto navbar-center" id="mySidenav">
       <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
         <a href="index" [ngxScrollTo]="'#home'" class="nav-link">Home</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
         <a href="index#features" [ngxScrollTo]="'#features'" class="nav-link">Features</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
         <a href="index#pricing" [ngxScrollTo]="'#pricing'" class="nav-link"
           [ngxScrollToOffset]="5">Pricing</a>
       </li>
       <li class="nav-item" [ngClass]="{'active':currentSection === 'contact'}">
         <a href="index#contact" [ngxScrollTo]="'#contact'" class="nav-link" [ngxScrollToOffset]="5">Contact
           Us</a>
       </li>
     </ul>
     <ul class="navbar-nav navbar-center">
       <li class="nav-item">
         <a href="https://demo.soficloud.com" target="_blank" class="nav-link" >Demo</a>
       </li>
     </ul>
   </div>
 </div>
</nav>
<!-- Navbar End -->

<div appScrollspy [spiedTags]="['SECTION']" >
  <!-- Hero Start -->
  <section class="hero-1-bg" style="background-image: url(assets/images/hero-1-bg-img.png)" id="home">
    <div class="container">
      <h1 class="hero-1-title text-dark font-weight-bold text-shadow mb-4">Privacy Policy</h1>
      <h3 class="title mb-3">Kebijakan Privasi</h3>
      <p class="">
        PT Inforsys Indonesia (selanjutnya disebut <code>Inforsys</code>) mengumpulkan alamat e-mail dari orang yang mengirimkan e-mail. Kami juga mengumpulkan informasi tentang apa akses halaman pengguna dan informasi yang diberikan kepada kami oleh pengguna melalui survei dan pendaftaran situs. Informasi tersebut mungkin berisi data pribadi tentang Anda termasuk alamat Anda, nomor telepon, nomor kartu kredit dll. <code>Inforsys</code> melindungi informasi kartu kredit sesuai dengan standar keamanan. Kami tidak diperbolehkan untuk mengungkapkan informasi pribadi tanpa izin tertulis dari Anda. Namun, informasi tertentu yang dikumpulkan dari Anda dan tentang Anda digunakan dalam konteks menyediakan layanan kami kepada Anda. Informasi yang kami kumpulkan tidak dibagi dengan, dijual atau disewakan kepada orang lain, kecuali dalam keadaan tertentu dan yang penggunaan Layanan tersebut dianggap telah mendapatkan persetujuan yang valid untuk mengungkapkan hal-hal berikut:
      </p>
      <ol>
        <li><code>Inforsys</code> dapat berbagi informasi pribadi dalam rangka untuk menyelidiki, mencegah, atau mengambil tindakan terkait dengan aktivitas ilegal, dugaan penipuan, situasi yang melibatkan kemungkinan ancaman terhadap keselamatan fisik seseorang, pelanggaran istilah <code>Inforsys</code> penggunaan, atau sebagaimana diharuskan oleh hukum.</li>
        <li><code>Inforsys</code> mempekerjakan perusahaan lain untuk melakukan tugas-tugas atas nama kami dan mungkin perlu untuk berbagi informasi Anda dengan mereka untuk menyediakan produk dan layanan kepada Anda.</li>
        <li>Kami akan mentransfer informasi tentang Anda jika <code>Inforsys</code> dipindahtangankan kepada perusahaan lain. Dalam hal ini, <code>Inforsys</code> akan memberitahu Anda melalui email atau dengan meletakkan pemberitahuan yang jelas di situs web <code>Inforsys</code> sebelum informasi tentang Anda yang sudah ditransfer menjadi tunduk pada kebijakan privasi yang berbeda.</li>
      </ol>

      <h3 class="title mb-3">Apa yang kami lakukan dengan informasi Anda</h3>
      <ol>
        <li>Istilah <code>Informasi Pribadi</code> yang digunakan di sini didefinisikan sebagai setiap informasi yang mengidentifikasi atau dapat digunakan untuk mengidentifikasi, menghubungi atau mencari orang kepada siapa berkaitan informasi tersebut. Informasi pribadi yang kami kumpulkan akan tunduk pada Kebijakan Privasi ini, sebagai telah diubah dari waktu ke waktu.</li>
        <li>Ketika Anda mendaftar untuk <code>Inforsys</code> kami menanyakan nama, nama perusahaan dan alamat email dan Anda menyatakan kesediaan untuk memberikan informasi tersebut ketika Anda bergabung dengan layanan kami.</li>
        <li>Inforsys menggunakan informasi yang kami kumpulkan untuk keperluan umum berikut: produk dan jasa penyediaan, penagihan, identifikasi dan otentikasi, peningkatan layanan, kontak, dan penelitian.</li>
        <li>Sebagai bagian dari pembelian dan penjualan proses pada Inforsys, Anda akan mendapatkan alamat email dan / atau alamat pengiriman dari pelanggan Anda. Anda setuju bahwa, sehubungan dengan Informasi Pribadi pengguna lain yang Anda dapatkan melalui Inforsys atau melalui komunikasi Inforsys terkait atau transaksi yang difasilitasi Inforsys, Inforsys dengan ini memberikan kepada Anda lisensi untuk menggunakan informasi tersebut hanya untuk komunikasi komersial yang menjadi tanggung jawab Anda tanpa melibatkan Inforsys. Inforsys tidak mentolerir spam. Oleh karena itu, tanpa mengabaikan ketentuan di atas, Anda tidak diperbolehkan untuk menambahkan nama seseorang yang telah membeli item dari Anda, untuk daftar email Anda (email atau surat fisik) tanpa persetujuan mereka.</li>
      </ol>

      <h3 class="title mb-3">Keamanan</h3>
      <p class="">
        Keamanan informasi pribadi Anda adalah sangat penting bagi kami. Bila Anda memasukkan informasi sensitif, seperti nomor kartu kredit di formulir pendaftaran kami, kami mengenkripsi transmisi informasi menggunakan teknologi socket layer yang aman (SSL), namun tidak ada yang bersifat 100% terlebih cara Anda menggunakan informasi Anda akan sangat berpengaruh. Sementara kami berusaha untuk menggunakan cara-cara yang dapat diterima secara komersial untuk melindungi informasi pribadi Anda, kami tidak dapat menjamin keamanan mutlak. Jika Anda memiliki pertanyaan tentang keamanan di situs Web kami, Anda dapat email kami di hello@inforsys.co.id
      </p>

      <h3 class="title mb-3">Pengungkapan</h3>
      <ol>
        <li>Inforsys dapat menggunakan penyedia layanan pihak ketiga untuk menyediakan layanan tertentu kepada Anda dan kami dapat berbagi informasi pribadi dengan penyedia layanan tersebut. Kami memerlukan perusahaan yang dapat berbagi informasi pribadi untuk melindungi data yang secara konsisten dengan kebijakan ini dan untuk membatasi penggunaan informasi pribadi tersebut kepada kinerja pelayanan untuk Inforsys.</li>
        <li>Inforsys dapat mengungkapkan informasi pribadi dalam situasi khusus, seperti untuk mematuhi perintah pengadilan mengharuskan kami untuk melakukannya atau ketika tindakan Anda melanggar Ketentuan Layanan.</li>
        <li>Inforsys tidak menjual atau memberikan informasi pribadi kepada perusahaan lain untuk memasarkan produk atau jasa mereka sendiri.</li>
      </ol>

      <h3 class="title mb-3">Cookies</h3>
      <p class="">
        Cookie adalah sejumlah kecil data, yang mungkin termasuk pengenal unik anonim. Cookie dikirim ke browser Anda dari situs web dan disimpan pada hard drive komputer Anda. Setiap komputer yang mengakses website kami ditugaskan cookie untuk menyimpan informasi tersebut.
      </p>

      <h3 class="title mb-3">Google Analytics dan Pemasaran</h3>
      <p class="">
        Inforsys menggunakan layanan yang disediakan oleh Google yang disebut Google Analytics (GA). GA memungkinkan kami untuk menjangkau orang-orang yang sebelumnya telah mengunjungi situs kami, dan menunjukkan kepada mereka iklan yang relevan ketika mereka mengunjungi situs lain di Internet dalam Jaringan Display Google.
      </p>
      <p class="">
        Cookie dapat digunakan untuk melacak sesi Anda di website kami, untuk melayani iklan disesuaikan dari Google dan vendor pihak ketiga lainnya. Ketika Anda mengunjungi situs ini, Anda mungkin melihat iklan yang diposting di situs oleh Google atau pihak ketiga lainnya. Melalui pihak pertama dan cookie pihak ketiga, pihak ketiga tersebut dapat mengumpulkan informasi tentang Anda ketika Anda mengunjungi situs ini dan situs-situs lain. Mereka mungkin menggunakan data ini untuk menampilkan iklan di situs ini dan di Internet berdasarkan kunjungan Anda sebelum website ini dan di tempat lain di Internet. Kami tidak mengumpulkan informasi ini atau mengontrol isi dari iklan yang akan Anda lihat.
      </p>
      <p class="">
        Anda mungkin dapat memilih keluar dari iklan Jaringan Display Google disesuaikan dengan mengunjungi Preferensi Iklan <code>http://www.google.com/ads/preferences/</code>, dan Google Analytics Opt-out Browser Add-on <code>http://www.google.ca/ads/preferences/plugin/</code>.
      </p>
      <p class="">
        Penggunaan Anda atas situs ini tanpa memilih keluar berarti bahwa Anda memahami dan menyetujui pengumpulan data untuk menyediakan Anda dengan pemasaran ulang iklan menggunakan GA dan cookie dari vendor pihak ketiga lainnya berdasarkan kunjungan Anda sebelum website ini dan di tempat lain di Internet.
      </p>

      <h3 class="title mb-3">Perubahan terhadap Kebijakan Privasi</h3>
      <p class="">
        Inforsys berhak untuk mengubah pernyataan privasi ini setiap saat, sehingga Anda disarankan untuk selalu meninjau ulang perubahan sesering mungkin. Jika kami membuat perubahan penting terhadap kebijakan ini, kami akan memberitahu Anda di sini atau dengan cara pemberitahuan di homepage kami sehingga Anda mengetahui informasi apa yang kami kumpulkan dan bagaimana kami menggunakannya.
      </p>
    </div>
  </section>
  <!-- Hero End -->
  <app-footer></app-footer>
</div>
